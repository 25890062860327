import React from 'react'
import './Footer.css'

const MenuBar = () => {
    return (
        <footer >
            <div className="footer">
                <p>&copy; Foxble LLC</p>
                <p>San Francisco, California</p>
            </div>
        </footer>
    )
}

export default MenuBar;