import React from 'react'
import Header from '../navigations/Header'

const Home = () => {
    return (
        <React.Fragment>
            <Header />
        </React.Fragment>
    )
}

export default Home;
