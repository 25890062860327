import React from 'react'
import './Header.css'

const Header = () => {
    return (
        <React.Fragment>
            <header className="bg-image">
                <div className="bg-container">
                    <h1>Global Wireless IoT</h1>
                    <h2>Connecting devices in real time</h2>
                    <h2>Satellite Cellular BLE</h2>
					<br /> 
					<br /> 
					<br /> 
					<br /> 
					<img src="banner728x90ehx.jpg" alt="banner728x90ehx" />
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header;