import React from 'react'

const About = () => {
    return (
        <React.Fragment>
            <section className="content-container">
                <div className="textArea"> 
                    <h2>About</h2>
			          <p>
			            FOXBLE LLC engineers and assembles environmental sensing bluetooth low energy devices for informational purposes in the USA.
			          </p>
			          <p>
			            Upcoming satellite and cellular gateway versions will expand our global connectivity and capabilities.
			          </p>
                </div>
            </section>
        </React.Fragment>
    )
}

export default About;