import React from 'react'

const Support = () => {
    return (
        <React.Fragment>
            <section className="content-container">
                <div className="textArea"> 
                    <h2>Support</h2>
						<p>Instructions on how to use FXB102.</p>
							<img src="fxb102_instructions_1.png" alt="fxb102_1" />
							<img src="fxb102_instructions_2.png" alt="fxb102_2" />
							<img src="fxb102_instructions_3.png" alt="fxb102_3" />
							<img src="fxb102_instructions_4.png" alt="fxb102_4" />
							<img src="fxb102_instructions_5.png" alt="fxb102_5" />
							<img src="fxb102_instructions_6.png" alt="fxb102_6" />
							<img src="fxb102_instructions_7.png" alt="fxb102_7" />
							<img src="fxb102_instructions_8.png" alt="fxb102_8" />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Support;