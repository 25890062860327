import React from 'react'

const Policies = () => {
    return (
        <React.Fragment>
            <section className="content-container">
                <div className="textArea"> 
                    <h2>Policies</h2>
			          <p><b>Privacy Policy</b></p>
			          <p>
				  		Foxble, LLC does not collect any personal data from our customers.
			          </p>
			          <p>
				  		Foxble, LLC has no provisions, nor servers and no method of collecting data by regular use of our app when checking device 						details external to the mobile like temperature. Also, our Android and Apple iOS apps do not collect any personal data in order to 						use the app.
				  	  </p>
			          <p>
				  		If you contact us by email, we will only use your email address to contact you to resolve your support request.
				  	  </p>
			          <p>
				  		We will never provide your email address to any other company or 3rd party.
				  	  </p>
			          <p>
				  		We utilize Amazon Web Services to collect IoT data from external device details if the user decides to activate the MQTT 						feature, and only data from the external device details like temperature, pressure, humidity, elevation and external device id, and 						do not collect the users name, email, location, mobile number, contacts, search, activity, history or any other piece of 						information related to the user, installed apps or from the mobile device itself.
				  	  </p>	  
			          <p><b>Disclaimer of Warranties & Limitation of Liability (Terms & Conditions)</b></p>
			          <p>
				  		Foxble, LLC reserves the right to make changes without further notice to any of its products herein. Foxble,	  
				  		LLC makes no warranty, representation of guarantee regarding the suitability of its products for any particular purpose, nor 						does Foxble, LLC assume any liability arising out of the application or use of any product or circuit, and specifically disclaims 						any and all liability, including without limitation consequential or incidental damages. Typical parameters can and do vary in 						different applications. All operating parameters, including Typicals must be validated for each customer application by customers 						technical experts.	  
			          </p>
			          <p>
				  		Foxble, LLC does not convey any license under its patent rights nor the rights of others. Foxble, LLC products are not 						designed, intended, or authorized for use as components in systems intended for surgical implant into the body, or other 						applications intended to support or sustain life, or for any other application in which the failure of the Foxble, LLC product 						could create a situation where personal injury or death may occur.
				  	  </p>
			          <p>
				  		Should Buyer purchase or use Foxlbe, LLC products for any such unintended or unauthorized application, Buyer shall indemnify 						and hold Foxble, LLC and its offices, employees, subsidiaries, affiliates, and distributors harmless against all claims, costs, 						damages, and expenses, and reasonable attorney fees arising out of, directly or indirectly, any claim of personal injury or 						death associated with such unintended or unauthorized use, even if such claim alleges that Foxble, LLC was negligent regarding 						the design or manufacture of the part.
				  	  </p>
			          <p>
				  		Foxble, LLC endeavors to ensure that the information in this document is correct and fairly stated but does not accept 						liability for any error or omission. The development of Foxble, LLC products and services is continuous and published information 						may not be up to date. It is important to check the current position with Foxble, LLC.	  	  
				      </p>
			          <p>
				  		Foxble, Enviro, Tempo, Alto, Airo are trademarks of Foxble, LLC. All other trademarks belong to their respective owners and are 						recognized and acknowledged.
				  	  </p>	  	  				
                </div>
            </section>
        </React.Fragment>
    )
}

export default Policies;